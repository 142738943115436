import React, { useState } from 'react'
import { Avatar, Paper, Grid, Typography, Container, Button } from '@material-ui/core';
import useStyles from './Styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Input } from './Input';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { signIn,signUp } from '../../actions/auth';

const initialFormData = {firstName:'',lastName:'',email:'',password:'',confirmPassword:'',};
const Auth = () => {
    const [showPassword,setShowPassword] = useState(null);
    const classes = useStyles();
    const [isSignUp, setIsSignUp] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [formData, setFormData] = useState(initialFormData);
    const handleSubmit = (e) => {
        e.preventDefault();
        if(isSignUp){
            dispatch(signUp(formData,history));
        }else{
            dispatch(signIn(formData,history));
        }
    }
    const handleChange = (e) => {
        setFormData({...formData,[e.target.name]:e.target.value});
    }
    const handlePasswordShow = () => setShowPassword( (prevShowPassword) => !prevShowPassword )
    const switchMode = () => {
        setIsSignUp((prevIsSignUp) => !prevIsSignUp );
        handlePasswordShow(false);
    }

    const googleSuccess = async (response) => {
       const decoded = jwt_decode(response?.credential);
       const token = response?.credential;
       const result = {
             _id:decoded.sub,
             googleId:decoded.sub,
             _type:'user',
             name:decoded.name,
             email:decoded.email,
             imageUrl:decoded.picture
            };
        try {
                dispatch({type:'AUTH', data:{ result, token} });
                history.push('/');
            } catch (error) {
                console.log(error);
            }
    }

    return (
        <Container component="main" maxWidth="xs">
            <Paper className={classes.paper} elevation={3}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography variant='h4'>{isSignUp ? 'Sign Up' : 'Sign In'}</Typography>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        {isSignUp && (
                            <>
                            <Input name='firstName' label='First Name' handleChange={handleChange} autoFocus half />
                            <Input name='lastName' label='Last Name' handleChange={handleChange} half />
                            </>
                        )}
                        <Input name='email' label='Email' handleChange={handleChange}  type='email' />
                        <Input name='password' label='Password' handleChange={handleChange}  type={showPassword ? "text" : "password"} handlePasswordShow={handlePasswordShow} />
                        { isSignUp && <Input name='confirmPassword' label='Repeat Password' handleChange={handleChange}  type="password" /> }
                    </Grid>
                    <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit}>
                        {isSignUp ? 'Sign Up' : 'Sign In'}
                    </Button>
                    <GoogleLogin onSuccess={(response) => googleSuccess(response)} onError={(error)=>console.log(error)} />
                    <Grid container justifyContent='flex-end'>
                        <Grid item>
                            <Button onClick={switchMode}>
                                {isSignUp ? 'Already have an account? Sign In' : "Don't have an account? Sign up"}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    )
}

export default Auth

// max prod = budget/2.
