import React, {useState,useEffect} from "react";
import { Container,Grow,Grid, Paper,AppBar,TextField,Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Posts from "../Posts/Posts";
import Form from "../Form/Form";
import { getPosts,getPostsBySearch } from '../../actions/posts';
import useStyles from './styles';
import CustomPagination from "../Pagination";
import { useHistory,useLocation } from "react-router-dom";
import ChipInput from 'material-ui-chip-input';

function useQuery(){
    return new URLSearchParams(useLocation().search);
}

const Home = () => {
    const [currentId,setCurrentId] = useState(null);
    const dispatch = useDispatch();
    const query = useQuery();
    const history = useHistory();
    const page = query.get('page') || 1;
    const searchQuery = query.get('searchQuery');
    const classes = useStyles();
    const [search,setSearch] = useState('');
    const [tags,setTags] = useState([]);

    const searchPost = () => {
        if(search.trim() || tags){
            dispatch(getPostsBySearch({ search,tags:tags.join(',') }));
            history.push(`/posts/search?searchQuery=${search || 'none'}&tags=${tags.join(',')}`)
        }else{
            history.push('/');
        }
    }

    const handleKeyPress = (e) => {
            if(e.keyCode === 13){
                searchPost();
            }
    }
    const handleAddTag = (tag) => setTags([...tags,tag]);
    const handleDeleteTag = (tagToDelete) => setTags(tags.filter((tag)=> tag !== tagToDelete)); 

    return (
        <Grow in>
            <Container maxWidth='xl'>
            <Grid container justifyContent="space-between" alignItems="stretch" spacing={3} className={classes.girdContainer}>
                <Grid item xs={12} sm={6} md={9}>
                    <Posts setCurrentId={setCurrentId} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AppBar className={classes.appBarSearch} position="static" color="inherit">
                        <TextField
                            name="search"
                            variant="outlined"
                            label="Search Memories"
                            fullWidth
                            onKeyUp={handleKeyPress}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <ChipInput 
                            style={{margin:'10px 0'}}
                            value={tags}
                            onAdd={handleAddTag}
                            onDelete={handleDeleteTag}
                            label="Search Tag"
                            variant="outlined"
                        />
                        <Button color="primary" onClick={searchPost} variant="contained" className={classes.searchButton}>Search</Button>
                    </AppBar>
                    <Form currentId={currentId} setCurrentId={setCurrentId} />
                    {(!searchQuery && !tags.length) && (
                    <Paper elevation={6} className={classes.pagination}>
                        <CustomPagination page={page} />
                    </Paper>
                    )}
                    

                </Grid>
            </Grid>
            </Container>
        </Grow>
    );
}

export default Home;